<template>
  <div>
    <div class="win-chip">
      <span class="prefix">+</span>
      <span class="amount">
        {{ getShortChipFormat(amount) }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "WinChipAnimation",
  data() {
    return {
      innerAmount: [],
    };
  },
  props: {
    amount: {
      type: [Number, String],
      required: false,
      default: "",
    },
  },
  watch: {
    amount: {
      handler(val) {
        if (val > 0) {
          this.innerAmount.push(val);
          // setTimeout(() => {
          //   this.innerAmount.shift();
          // }, 2000);
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      getShortChipFormat: "getShortChipFormat",
    }),
  },
  // mounted() {
  //   this.$refs.winChipAnimationSprite.$refs.winChipAnimationSprite.playOnce()
  // }
};
</script>

<style scoped lang="scss">
.seat-index-0,
.seat-index-1,
.seat-index-2,
.seat-index-8 {
  .win-chip {
    left: unset;
    right: 65px;
  }
}
</style>
